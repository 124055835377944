import { Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntercomProvider } from 'react-use-intercom';

import App from './App';
import './design-system/styles/main.scss';
import theme from './design-system/theme';
import AnalyticsProvider from './providers/AnalyticsProvider';
import { queryClientConfig } from './queries/api';
import { SharedRoutes } from './routes/appRoutes';
import { SESSION_ID } from './session';

// For some reason DONT_USE_SENTRY doesn't not work in review apps, this is a hack to skip Sentry in review apps
const isHerokuReviewApp = window.location.host.includes('herokuapp');

if (
  (import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production') &&
  import.meta.env.DONT_USE_SENTRY !== 'true' &&
  !isHerokuReviewApp
) {
  Sentry.init({
    dsn: 'https://e6cef07c07c84f7ca69f33d0cf82870a@o1027089.ingest.sentry.io/6288988',
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ],
    environment: import.meta.env.MODE,
    release: __COMMIT_HASH__,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });

  Sentry.configureScope(function (scope) {
    scope.setTag('session_id', SESSION_ID);
  });
}

async function prepare() {
  // Start MSW for development use only
  if (import.meta.env.VITE_MSW_ENABLED) {
    const { worker } = await import('./mocks/browser');
    worker.start();
  }

  return Promise.resolve();
}

const INTERCOM_APP_ID = 'rjsxlzgp';

const queryClient = new QueryClient(queryClientConfig);

// load the react-query devtool component only when needed
const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
    default: d.ReactQueryDevtools,
  })),
);

const Main = () => {
  // add a method in the window object `toggleDevtools` to enable the devtools
  // in a production build to help when debugging issues that cannot reproduced easily.
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools(old => !old);
  }, []);

  return (
    <Auth0Provider
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="localstorage" // so it stays logged in when refreshing on Safari
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE, // the backend API in Auth0
      }}
      skipRedirectCallback={window.location.pathname === SharedRoutes.DIALPAD_AUTH_CALLBACK} // this prevents Auth0 from intercepting our callback from Dialpad
    >
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <AnalyticsProvider>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
              <App />
            </ChakraProvider>

            {/* include react-query devtool for inspecting the cache */}
            <ReactQueryDevtools initialIsOpen={false} />
            {showDevtools && (
              <React.Suspense fallback={null}>
                <ReactQueryDevtoolsProduction />
              </React.Suspense>
            )}
          </QueryClientProvider>
        </AnalyticsProvider>
      </IntercomProvider>
    </Auth0Provider>
  );
};

// Don't load the app until MSW has started (if enabled)
prepare().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <React.StrictMode>
      <Main />
    </React.StrictMode>,
  );
});
