import z from 'zod';

import { nonnegativeInteger } from './utils';

export const integerString = z.preprocess(val => parseInt(val as string), z.number());

export const OrganizationIdSchema = integerString.brand<'OrganizationId'>();
export type OrganizationId = z.infer<typeof OrganizationIdSchema>;

export const AccountTokenSchema = z.string().brand<'accountToken'>();
export type AccountToken = z.infer<typeof AccountTokenSchema>;

export const AccountTypeSchema = z.enum(['ticketing', 'hris']);
export type AccountType = z.infer<typeof AccountTypeSchema>;

export const SurferIdSchema = integerString.brand<'SurferId'>();
export type SurferId = z.infer<typeof SurferIdSchema>;

export const MergeIdSchema = z.string().brand<'MergeId'>();
export type MergeId = z.infer<typeof MergeIdSchema>;

export const ManagerIdSchema = integerString.brand<'ManagerId'>();
export type ManagerId = z.infer<typeof ManagerIdSchema>;

export const DialpadUserIdSchema = z.string().brand<'DialpadUserId'>();
export type DialpadUserId = z.infer<typeof DialpadUserIdSchema>;

export const DialpadOfficeIdSchema = z.string().brand<'DialpadOfficeId'>();
export type DialpadOfficeId = z.infer<typeof DialpadOfficeIdSchema>;

export const TeamIdSchema = integerString.brand<'TeamId'>();
export type TeamId = z.infer<typeof TeamIdSchema>;

export const PlanIdSchema = integerString.brand<'PlanId'>();
export type PlanId = z.infer<typeof PlanIdSchema>;

export const HolidayIdSchema = z.union([integerString.brand<'HolidayId'>(), z.string()]);
export type HolidayId = z.infer<typeof HolidayIdSchema>;

export const PlanRefSchema = z.object({
  id: PlanIdSchema,
  version: nonnegativeInteger,
});
export type PlanRef = z.infer<typeof PlanRefSchema>;

export const ShiftTemplateIdSchema = integerString.brand<'ShiftTemplateId'>();
export type ShiftTemplateId = z.infer<typeof ShiftTemplateIdSchema>;

export const ShiftTemplateRefSchema = z.object({
  id: ShiftTemplateIdSchema,
  version: nonnegativeInteger,
});
export type ShiftTemplateRef = z.infer<typeof ShiftTemplateRefSchema>;

export const TaskIdSchema = integerString.brand<'TaskId'>();
export type TaskId = z.infer<typeof TaskIdSchema>;

export const TaskRefSchema = z.object({
  id: TaskIdSchema,
  version: nonnegativeInteger,
});
export type TaskRef = z.infer<typeof TaskRefSchema>;

export const isTaskRef = (input: unknown): input is TaskRef =>
  TaskRefSchema.safeParse(input).success;

export const ScheduledTaskIdSchema = z.string().brand<'ScheduledTaskId'>();
export type ScheduledTaskId = z.infer<typeof ScheduledTaskIdSchema>;

export const TaskTemplateIdSchema = integerString.brand<'TaskTemplateId'>();
export type TaskTemplateId = z.infer<typeof TaskTemplateIdSchema>;

export const TaskTemplateRefSchema = z.object({
  id: TaskTemplateIdSchema,
  version: nonnegativeInteger,
});
export type TaskTemplateRef = z.infer<typeof TaskTemplateRefSchema>;

export const CalendarIdSchema = z.string().brand<'CalendarId'>();
export type CalendarId = z.infer<typeof CalendarIdSchema>;

export const BlockedTimeIdSchema = integerString.brand<'BlockedTimeId'>();
export type BlockedTimeId = z.infer<typeof BlockedTimeIdSchema>;

export const StreamIdSchema = integerString.brand<'StreamId'>();
export type StreamId = z.infer<typeof StreamIdSchema>;

export const ProviderIdSchema = z.number().brand<'ProviderId'>();
export type ProviderId = z.infer<typeof ProviderIdSchema>;

export const ConnectionIdSchema = integerString.brand<'ConnectionId'>();
export type ConnectionId = z.infer<typeof ConnectionIdSchema>;

export const SnapshotIdSchema = z.number().brand<'SnapshotId'>();
export type SnapshotId = z.infer<typeof SnapshotIdSchema>;

export const RoundRobinIdSchema = z.string().min(1).brand<'RoundRobinId'>();
export type RoundRobinId = z.infer<typeof RoundRobinIdSchema>;

export const SurferAvailabilityIdSchema = integerString.brand<'SurferAvailabilityId'>();
export type SurferAvailabilityId = z.infer<typeof SurferAvailabilityIdSchema>;

export const ScheduleChangeRequestIdSchema = integerString.brand<'ScheduleChangeRequestId'>();
export type ScheduleChangeRequestId = z.infer<typeof ScheduleChangeRequestIdSchema>;

export const LinkedAccountIdSchema = z.string().brand<'LinkedAccountId'>();
export type LinkedAccountId = z.infer<typeof LinkedAccountIdSchema>;

export const MergeTicketIdSchema = z.string().min(1).brand<'MergeTicketId'>();
export type MergeTicketId = z.infer<typeof MergeTicketIdSchema>;

export const MergeCommentIdSchema = z.string().min(1).brand<'MergeCommentId'>();
export type MergeCommentId = z.infer<typeof MergeCommentIdSchema>;

export const RemoteCommentIdSchema = z.string().min(1).brand<'RemoteCommentId'>();
export type RemoteCommentId = z.infer<typeof RemoteCommentIdSchema>;

export const RemoteTicketIdSchema = z.string().min(1).brand<'RemoteTicketId'>();
export type RemoteTicketId = z.infer<typeof RemoteTicketIdSchema>;

export const MergeUserIdSchema = z.string().brand<'MergeUserId'>();
export type MergeUserId = z.infer<typeof MergeUserIdSchema>;
