import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { Image, VStack } from '@chakra-ui/react';

import { Button } from '../design-system/components/Button';
import Heading from '../design-system/components/Heading';
import { Link } from '../design-system/components/Link';
import { Panel } from '../design-system/components/Panel';
import { Section } from '../design-system/components/Section';
import { Text } from '../design-system/components/Text';
import fishy from '../images/fish-yellow-transparent.png';
import { AuthenticatedUser } from '../types/types';

const useErrorDetails = (error: Error): { type: string; description: string } => {
  const defaultType = 'Unknown';
  const defaultDescription = error.message;

  const location = window.location;
  const queryString = new URLSearchParams(location.search);

  const type = (error as OAuthError).error ?? queryString.get('error') ?? defaultType;
  const description =
    (error as OAuthError).error_description ??
    queryString.get('error_description') ??
    defaultDescription;

  return { type, description };
};

export const AuthErrorPage: React.FC<React.PropsWithChildren<{ error: Error }>> = ({ error }) => {
  const { logout } = useAuth0<AuthenticatedUser>();
  const { type, description } = useErrorDetails(error);

  return (
    <Panel direction="column" justify="center" lineSeparated grow>
      <Section display="flex" alignItems="flex-start" justifyContent="center">
        <VStack spacing="4">
          <Heading as="h1">Login failed</Heading>
          <Image src={fishy} maxWidth="360px" maxHeight="240px" />
          <Text size="f3">
            Please try again or check the error code listed below in{' '}
            <Link
              style={{ display: 'inline', color: 'inherit' }}
              target="_blank"
              href="https://intercom.help/teamsurfboard/en/articles/8564687-troubleshooting-login-issues"
              text="our troubleshooting guide"
            />{' '}
            for further assistance.
          </Text>
          <Text fontFamily="monospace" alignSelf="self-start">
            Error: {type}
          </Text>
          <Text fontFamily="monospace" alignSelf="self-start">
            Description: {description}
          </Text>
          <Button size="lg" label="Log in" onClick={() => logout()} variant="primary">
            Log in
          </Button>
        </VStack>
      </Section>
    </Panel>
  );
};
