export const isSettingsPagePath = (path: string) => {
  return path.startsWith('/settings/');
};

export enum SharedRoutes {
  LOGIN = '/login',
  TEMP_LOGIN = '/temp-login',
  DIALPAD_AUTH_CALLBACK = '/auth/callback',
}

export enum ManagerRoutes {
  HOME = '/home',

  SCHEDULE = '/schedule',
  SCHEDULE_TASK_BUILDER = '/schedule/tasks/builder',
  SCHEDULE_TASK_BUILDER_EDIT = '/schedule/tasks/builder/:taskTemplateId',

  SHIFT_PLANS = '/shift-plans',
  SHIFT_PLAN = '/shift-plans/:planId',
  SHIFT_PLAN_PATTERNS = '/shift-plans/:planId/patterns',

  FORECASTS = '/forecasts/forecast',
  FORECASTS_PLAN = '/forecasts/plan',
  FORECASTS_TICKET_GROUPS = '/forecasts/ticket-groups',

  TEAM_SURFERS = '/surfers-teams',

  REPORTING_REAL_TIME = '/reporting/real-time',
  REPORTING_INSIGHTS = '/reporting/insights/:timeUnit?',
  REPORTING_INSIGHTS_DEFAULT = '/reporting/insights',
  REPORTING_PERFORMANCE = '/reporting/performance',
  REPORTING_TIMESHEETS = '/reporting/timesheets',
  REPORTING_DEFAULT = '/reporting',

  REQUESTS_INBOX = '/requests',

  SETTINGS_TIME_OFF = '/settings/time-off',
  SETTINGS_MANAGE_ACCESS = '/settings/managers',
  SETTINGS_CONNECTIONS = '/settings/connections',
  SETTINGS_BILLING = '/settings/billing',
}

export enum SurferRoutes {
  SHIFTS = '/shifts',
  SLACK_SUCCESS = '/slack-success',
  PERFORMANCE = '/performance',
  GOOGLE_AUTH_CALLBACK = '/google-oauth-callback',
}

export enum SurfboardRoutes {
  FEATURES = '/settings/features',
}
