import { useHistory } from 'react-router-dom';

import { useExchangeAuthCodeForToken } from '../queries/auth';

// The pending and error return states are placeholders until we finalise the login page design
export const AuthCallback = () => {
  const history = useHistory();

  const { isPending, isError, error, isSuccess } = useExchangeAuthCodeForToken();

  if (isPending) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  if (isSuccess) {
    history.push('/temp-login');
  }

  return <></>;
};
