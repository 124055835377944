import { Button, Center } from '@chakra-ui/react';

import { useAuthUrl } from '../queries/auth';

export const TempLoginPage = () => {
  const { isLoading, data } = useAuthUrl();

  return (
    <Center height="100%">
      <Button
        as="a"
        variant="primary"
        isLoading={isLoading}
        href={data?.url?.replace('https:', '') || ''}
      >
        Login
      </Button>
    </Center>
  );
};
