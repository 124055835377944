import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

import { useOnMount } from '../hooks/useOnMount';
import { CenteredLoading } from '../shared-components/CenteredLoading';

export const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const loginParams: RedirectLoginOptions = {
    authorizationParams: {},
  };

  if (loginParams.authorizationParams) {
    if (query.get('invitation')) {
      loginParams.authorizationParams.invitation = query.get('invitation') || undefined;
    }
    if (query.get('organization')) {
      loginParams.authorizationParams.organization = query.get('organization') || undefined;
    }
  }

  useOnMount(() => {
    loginWithRedirect(loginParams);
  });

  return <CenteredLoading />;
};
