import { z } from 'zod';

import { DialpadUserIdSchema } from './ids';

export const OfficeSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const CreateDialpadAgentSchema = z.object({
  id: DialpadUserIdSchema,
  type: z.literal('dialpad'),
});
