import { queryOptions, useQuery } from '@tanstack/react-query';
import { OAuthAuthorizeUrlSchema } from 'shared-types';
import { z } from 'zod';

import { useRequest } from './api';

// To prevent Cross Site Request Forgery (CSRF) we generate and store a unique id that is also appended to the authorization URL. We then validate by matching the value coming from the response with our stored value.
export const OAUTH_STATE_KEY = 'oauth_random_id';
const storeOAuthState = (id: string) => sessionStorage.setItem(OAUTH_STATE_KEY, id);
const validateOAuthState = () => {
  const stateParam = new URLSearchParams(window.location.search).get('state');
  const storedId = sessionStorage.getItem(OAUTH_STATE_KEY);

  if (!stateParam || !storedId) {
    return false;
  }

  return stateParam === storedId;
};
const clearOAuthState = () => sessionStorage.removeItem(OAUTH_STATE_KEY);

export const useAuthUrl = () => {
  const request = useRequest();

  const options = queryOptions({
    queryKey: ['auth', 'url'],
    queryFn: () =>
      request(`/auth/url`, OAuthAuthorizeUrlSchema, {
        method: 'GET',
        useExactPathProvided: true,
      }),
    gcTime: 0,
    select: data => {
      const id = crypto.randomUUID();
      storeOAuthState(id);
      return {
        url: `${data.url}&state=${id}`,
      };
    },
  });

  return useQuery(options);
};

export const useExchangeAuthCodeForToken = () => {
  const request = useRequest();

  const options = queryOptions({
    queryKey: ['auth', 'token'],
    queryFn: () => {
      if (validateOAuthState()) {
        clearOAuthState();
        return request(`/auth/token${window.location.search}`, z.any(), {
          method: 'GET',
          useExactPathProvided: true,
        });
      } else {
        throw new Error('OAuth state mismatch');
      }
    },
    gcTime: 0,
    retry: false,
  });

  return useQuery(options);
};
